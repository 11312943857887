<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Activity Adjustment">
      <template #page-header>
        <h2 class="page-title">Activity Adjustment</h2>
        <r-menu-tab :level="3"></r-menu-tab>
      </template>
      <template #action-bar>
        <RequestHeader
          :search="true"
          @addNew="openModalRequest"
          :lodaData="isActivityAttendancePersonalLoading"
          @onSearch="searchIconClick($event)"
        ></RequestHeader>
      </template>

      <template #page-content>
        <div
          id="table-request-employment"
          class="table-timeoff-type table-request-personal"
          v-if="currentUser != null"
        >
          <b-table
            :data="activityAttendancePersonal"
            :per-page="perPage"
            :loading="isActivityAttendancePersonalLoading"
            @page-change="onPageChange"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkbox-position="left"
            class="employment-directory-table table-log-activity-personal general-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column
                field="attendance_activities.start_time"
                label="Date"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ formatDate(props.row.date) }}
                </span>
              </b-table-column>
              <b-table-column
                field="old_activity_in"
                label="Activity In/ Out"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{
                    formatTime(
                      props.row.oldActivityIn,
                      props.row.oldActivityOut
                    )
                  }}
                </span>
              </b-table-column>
              <b-table-column
                field="request_activity_in"
                label="Change To"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{
                    formatTime(
                      props.row.newActivityIn,
                      props.row.newActivityOut
                    )
                  }}
                </span>
              </b-table-column>
              <b-table-column
                field="status"
                label="Status"
                v-slot="props"
                sortable
              >
                <span
                  :class="`is-capitalize
                    ${determineFCByStatus(props.row.status)}`"
                >
                  {{ props.row.status }}
                </span>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="created_at"
                label="Created At"
                sortable
                width="12%"
              >
                <span>
                  {{ formatDate(props.row.createdAt) }}
                </span>
              </b-table-column>
              <b-table-column
                field="action"
                label="Action"
                v-slot="props"
                centered
              >
                <b-icon
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="
                    openModalRequest(activityAttendancePersonal[props.index])
                  "
                ></b-icon>
                <b-icon
                  icon="eye"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="openSidebar(props.row)"
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
        <b-sidebar
          v-if="isRightBarOpen && form != null"
          can-cancel
          fullheight
          :fullwidth="fullWidth"
          :overlay="true"
          right
          v-model="isRightBarOpen"
        >
          <div class="rightbar-detail-top-content-container">
            <div class="right-bar-detail-top-content">
              <p class="has-text-weight-black is-size-4 title">
                Activity Adjustment Details
              </p>
              <b-icon
                icon="close"
                class="x-close-btn"
                custom-size="mdi-18px"
                @click.native="closeSidebar"
              ></b-icon>
              <img
                class="profile-picture"
                :src="determineProfilePicture(currentUser)"
              />

              <p class="has-text-weight-black is-size-4 full-name">
                {{ currentUser.firstName }} {{ currentUser.middleName }}
                {{ currentUser.lastName }}
              </p>
            </div>
            <div class="columns is-multiline p-4 is-size-6">
              <div class="column is-4">Date</div>
              <div class="column is-8 has-text-weight-bold">
                {{ selectedColumn.date }}
                <!-- {{ formatDate(selectedColumn.date) }} -->
              </div>
              <div class="column is-4">Clock in/ out</div>
              <div class="column is-8 has-text-weight-bold">
                {{
                  formatTime(
                    selectedColumn.oldActivityIn,
                    selectedColumn.oldActivityOut
                  )
                }}
              </div>
              <div class="column is-4">Change to</div>
              <div class="column is-8 has-text-weight-bold">
                {{
                  formatTime(
                    selectedColumn.newActivityIn,
                    selectedColumn.newActivityOut
                  )
                }}
              </div>
              <div class="column is-4">Status</div>
              <div class="column is-8 has-text-weight-bold is-capitalize">
                {{ selectedColumn.status }}
              </div>
              <div class="column is-12">
                <TimeOffApprovalHistoryComponent
                  :approval-history="selectedColumn.history"
                />
              </div>
              <div
                class="column is-12"
                v-if="
                  selectedColumn.status === 'waiting' ||
                  selectedColumn.status === 'approved'
                "
              >
                <b-button
                  expanded
                  @click="
                    cancelActivityAttendance(
                      selectedColumn.id,
                      selectedColumn.newActivityIn,
                      selectedColumn.newActivityOut
                    )
                  "
                >
                  Cancel Request
                </b-button>
              </div>
            </div>
          </div>
        </b-sidebar>

        <ActivityAttendancePersonalModal
          :show="isModalOpen"
          :formData="form"
          :loadingSubmit="isSubmitting"
          :isEditing="isEditing"
          :listUser="listUser"
          :isFetchingUser="isFetchingUser"
          :notUpdatable="notUpdatable"
          :selectedUser="selectedUser"
          :maxDate="maxDate"
          :activityAttendanceOptions="activityAttendanceOptions"
          @hide="closeModalRequest"
          @submit="submit"
          @getUserList="getUserList($event)"
          @selectUser="selectUser($event)"
          @getMoreUserList="getMoreUserList"
          @formatTimeSelect="formatTimeSelect($event)"
          @getDataActivityAttendanceByDate="
            getDataActivityAttendanceByDate($event)
          "
        />
      </template>
    </r-page>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import {
  determineFontColorByStatus,
  formatDate,
  showToast,
} from '@/services/util'
import ApiService from '@/services/common/api.service'
import moment from 'moment-timezone'
import TimeOffApprovalHistoryComponent from '../TimeOff/TimeOffApprovalHistoryComponent.vue'
import isNotUpdatable from '../Script/isNotUpdatable'
import debounce from 'lodash/debounce'
import RequestHeader from '../../../components/Header/RequestHeader.vue'
import ActivityAttendancePersonalModal from './ActivityAttendancePersonalModal.vue'

export default {
  components: {
    TimeOffApprovalHistoryComponent,
    RequestHeader,
    ActivityAttendancePersonalModal,
  },
  data() {
    const today = new Date()
    return {
      offSetDiff: this.getOffsetDiff(),
      stickyHeaders: true,
      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,
      total: 0,

      defaultSortOrder: 'desc',
      sortField: 'created_at',
      sortOrder: 'desc',
      search: '',

      isActivityAttendancePersonalLoading: false,
      isLoadMore: false,
      isModalOpen: false,
      isEditing: false,
      notUpdatable: null,
      selectedColumn: null,
      isRightBarOpen: false,
      fullWidth: false,
      right: false,

      form: {
        id: null,
        selectedAttendanceOption: null,
        selectedDate: null,
        formattedStartTime: null,
        formattedEndTime: null,
        status: null,
        additionalApproverId: null,
      },

      startTime: null,
      endTime: null,

      activityAttendanceOptions: [],
      maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      isSubmitting: false,

      selectedUser: null,
      listUser: [],
      isFetchingUser: false,
      pageUserList: 1,
      lastPageUserList: 1,
      searchUser: null,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      activityAttendancePersonal:
        'activityAttendance/getActivityAttendancePersonal',
      activityAttendanceDetail:
        'activityAttendance/getActivityAttendanceDetail',
    }),
  },

  methods: {
    ...mapActions({
      actionLoadActivityAttendancePersonal:
        'activityAttendance/fetchActivityAttendancePersonal',
      actionLoadActivityAttendanceDetail:
        'activityAttendance/fetchActivityAttendanceDetail',
    }),

    ...mapMutations({
      setActivityAttendancePersonal:
        'activityAttendance/setActivityAttendancePersonal',
      setActivityAttendanceDetail:
        'activityAttendance/setActivityAttendanceDetail',
    }),

    /**
     * Offset time when user sets timezone to other region
     */
    getOffsetDiff() {
      // if you live in asia/jakarta, offset will be 420 (7 * 60 minute)
      let offsetLocal = moment.tz(new Date(), moment.tz.guess()).utcOffset()
      // if you use timezone asia/tokyo, offset will be 540 (9 * 60)
      let offSetTimeZone = moment(new Date()).utcOffset()

      let diff = offsetLocal - offSetTimeZone
      if (diff < 0) {
        diff *= -1
      }
      // convert from minutes to hours
      diff /= 60
      return diff
    },

    /**
     * Set table status color
     * @param {String} status - request status
     * @return determineFontColorByStatus method
     */
    determineFCByStatus(status) {
      return determineFontColorByStatus(status)
    },

    /**
     * Get activity attendance data using selected date
     * @param {Date} selectedDate - selected date
     */
    async getDataActivityAttendanceByDate(selectedDate) {
      let formattedDate = formatDate(selectedDate)

      try {
        if (selectedDate != null) {
          let response = await ApiService.get(
            `/api/space-roketin/attendance-activity?filtering_conditions[]=selectedDates&selected_duration[]=${formattedDate}&selected_duration[]=${formattedDate}`
          )
          this.activityAttendanceOptions = response.data.data
        } else {
          this.activityAttendanceOptions = []
        }
      } catch (e) {
        console.log(e)
      }
    },

    /**
     * Set Format Date
     * @param {dateString} str - date string
     * @return {dateString} formatted date
     */
    formatDate(str) {
      if (str) {
        return moment(str).format('DD MMMM YYYY')
      }
    },

    /**
     * Format Time using 'start-end' format
     * @param {Date} start - start time
     * @param {Date} end - end time
     */
    formatTime(start, end) {
      const startTime = moment(start).format('HH:mm')
      const endTime = moment(end).format('HH:mm')
      return `${startTime}-${endTime}`
    },

    /**
     * Set Time format
     * @param {Date} str - date time string
     * @return {Date} formatted time string
     */
    formatTimeSelect(str) {
      return moment(moment.utc(str).local().toISOString()).format('HH:mm')
    },

    /**
     * Open Request Modal. If row present,
     * set form to include data from selected table row
     * @param {integer} row - table row index (optional)
     */
    openModalRequest(row) {
      if (row) {
        this.isEditing = true
        this.form.id = row.id
        this.form.selectedDate = new Date(row.date)
        this.getDataActivityAttendanceByDate(this.form.selectedDate)

        this.form.formattedStartTime = new Date(
          moment(row.newActivityIn).format('YYYY-MM-DD HH:mm:ss')
        )
        this.form.formattedEndTime = new Date(
          moment(row.newActivityOut).format('YYYY-MM-DD HH:mm:ss')
        )

        this.form.selectedAttendanceOption = row.attendanceActivityId
        this.form.status = row.status

        this.selectedUser = row.history ? row.history[0].fullName : '-'
        this.notUpdatable = isNotUpdatable.check(row.status, row.history)
      }
      this.isModalOpen = true
    },

    /**
     * Close Request Modal
     */
    closeModalRequest() {
      this.resetForm()
      this.isModalOpen = false
      this.isEditing = false
    },

    /**
     * Load more attendance adjustment list data
     */
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadActivityAttendancePersonalList()
        this.isLoadMore = false
      }
    },

    /**
     * Reset form when close/ hide
     */
    resetForm() {
      this.form = {
        selectedAttendanceOption: null,
        startTime: null,
        endTime: null,
        additionalApproverId: null,
      }
    },

    /**
     * Reset state of table when form is created, updated
     * @param {String} searchInput - keyword search (optional)
     */
    async resetState(searchInput) {
      this.page = 0
      this.lastPage = 0
      this.setActivityAttendancePersonal([])
      await this.loadActivityAttendancePersonalList(searchInput)
    },

    /**
     * Search table data
     * @param {String} searchInput - keyword search
     */
    async searchIconClick(searchInput) {
      await this.resetState(searchInput)
    },

    /**
     * Load activity attendance list data
     * @param {String} searchInput - keyword search (optional)
     */
    async loadActivityAttendancePersonalList(searchInput) {
      this.isActivityAttendancePersonalLoading = true
      try {
        const response = await this.actionLoadActivityAttendancePersonal({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: searchInput,
        })

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isActivityAttendancePersonalLoading = false
    },

    /**
     * Submit activity attendance request
     */
    async submit() {
      try {
        let momentStartTime = moment(this.form.formattedStartTime)
        let startTimeUTC = momentStartTime
          .utc()
          .subtract(this.offSetDiff, 'hours')

        let momentEndTime = moment(this.form.formattedEndTime)
        let endTimeUTC = momentEndTime.utc().subtract(this.offSetDiff, 'hours')

        if (!this.isEditing) {
          let formRequest = new FormData()
          formRequest.append(
            'attendance_activity_id',
            this.form.selectedAttendanceOption
          )

          formRequest.append('activity_in', startTimeUTC.format('HH:mm:ss'))
          formRequest.append('activity_out', endTimeUTC.format('HH:mm:ss'))
          formRequest.append(
            'additional_approver_id',
            this.form.additionalApproverId
          )

          if (!this.form.additionalApproverId) {
            formRequest.delete('additional_approver_id')
          }

          this.isSubmitting = true

          let response = await ApiService.post(
            '/api/space-roketin/v3/attendance-activity-adjustment/request',
            formRequest,
            true
          )
          this.isSubmitting = false
          this.form = {
            attendance_id: '',
            start_time: null,
            end_time: null,
          }
          this.setActivityAttendancePersonal([
            ...this.activityAttendancePersonal,
            response.data.data,
          ])
          this.closeModalRequest()
          showToast('Request Added', 'is-success', 'is-top')
        } else {
          let formRequest = new FormData()
          formRequest.append('activity_in', startTimeUTC.format('HH:mm:ss'))
          formRequest.append('activity_out', endTimeUTC.format('HH:mm:ss'))
          formRequest.append('_METHOD', 'PUT')

          this.isSubmitting = true
          await ApiService.post(
            '/api/space-roketin/v3/attendance-activity-adjustment/request/' +
              this.form.id,
            formRequest,
            true
          )

          this.isSubmitting = false
          let activityAttendancePersonalVue = [
            ...this.activityAttendancePersonal,
          ]

          let index = activityAttendancePersonalVue.findIndex(
            (t) => t.id === this.form.id
          )

          if (index >= 0) {
            activityAttendancePersonalVue[
              index
            ].newActivityIn = startTimeUTC.toISOString()
            activityAttendancePersonalVue[
              index
            ].newActivityOut = endTimeUTC.toISOString()
          }
          this.setActivityAttendancePersonal([...activityAttendancePersonalVue])
          this.closeModalRequest()
          showToast('Edit Success', 'is-success', 'is-top')
        }
      } catch (e) {
        console.log(e)
        if (e.response?.message) {
          showToast(e.response.message, 'is-danger', 'is-top')
        }
      }
    },

    /**
     * Sort table
     * @param {String} field - name of field
     * @param {String} order - asc or desc
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     * Change page behavior
     * @param {integer} page - number of page
     */
    onPageChange(page) {
      this.page = page
      this.loadActivityAttendancePersonalList()
    },

    /**
     * Open Sidebar content from table
     * @param {Object} changeActivityAttendance - selected activity attendance
     * data from table row
     * @param {integer} id - selected data id
     */
    async openSidebar(changeActivityAttendance, id) {
      if (id) {
        let response = await this.actionLoadActivityAttendanceDetail(id)
        this.selectedColumn = response.data.data
      } else {
        this.selectedColumn = changeActivityAttendance
      }
      this.isRightBarOpen = true
    },

    /**
     * Close Sidebar
     */
    closeSidebar() {
      this.isRightBarOpen = false
    },

    /**
     * Determine Profile picture from database
     * @param {Array} user - user info that is currently logged in
     * @return {String} user profile picture url
     */
    determineProfilePicture(user) {
      if (!user.profilePictureUrl) {
        if (user.gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user.profilePictureUrl
    },

    /**
     * Select User from PIC database
     * @param {Object} option - selected user object (optional)
     */
    selectUser(option) {
      if (option) {
        this.selectedUser = option.fullName
        this.form.additionalApproverId = option.id
      }
    },

    /**
     * Get User list from database
     * @return {Object} listUser - User List
     */
    getUserList: debounce(function (name) {
      this.form.additionalApproverId = null
      if (this.selectedUser !== name) {
        this.selectedUser = name
        this.listUser = []
        this.pageUserList = 1
        this.lastPageUserList = 1
      }
      if (!name.length) {
        this.listUser = []
        this.pageUserList = 1
        this.lastPageUserList = 1
        return
      }
      this.isFetchingUser = true
      ApiService.get('/api/space-roketin/user/v3', {
        search: this.selectedUser,
        page: this.pageUserList,
      })
        .then((response) => {
          response.data.data.forEach((item) => this.listUser.push(item))
          this.pageUserList++
          this.lastPageUserList = response.data.meta.lastPage
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          this.isFetchingUser = false
        })
    }, 500),

    /**
     * Get more user list when auto complete scrolled
     * @return {Object} listUser - User List
     */
    getMoreUserList: debounce(function () {
      this.getUserList(this.selectedUser)
    }, 250),

    /**
     * Cancel activity attendance request
     * @param {integer} id - id activity attendance from table
     * @param {DateTime} startTime - start time
     * @param {DateTime} endTime - end time
     */
    async cancelActivityAttendance(id, startTime, endTime) {
      let momentStartTime = moment(startTime)
      let startTimeUTC = momentStartTime
        .utc()
        .subtract(this.offSetDiff, 'hours')

      let momentEndTime = moment(endTime)
      let endTimeUTC = momentEndTime.utc().subtract(this.offSetDiff, 'hours')
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: `Are you sure? You can't undo this action.`,
      })

      if (res && res.isConfirmed) {
        const form = new FormData()

        form.append('activity_in', startTimeUTC.format('HH:mm:ss'))
        form.append('activity_out', endTimeUTC.format('HH:mm:ss'))
        form.append('status', 'canceled')
        this.isSubmitting = true
        try {
          form.append('_METHOD', 'PUT')
          await ApiService.post(
            `/api/space-roketin/v3/attendance-activity-adjustment/request/${id}`,
            form,
            true
          ).then((response) => {
            if (response.status === 200) {
              let activityAttendancePersonalVue = [
                ...this.activityAttendancePersonal,
              ]

              let index = activityAttendancePersonalVue.findIndex(
                (t) => t.id === id
              )

              if (index >= 0) {
                activityAttendancePersonalVue[index] = response.data.data
              }
              this.setActivityAttendancePersonal([
                ...activityAttendancePersonalVue,
              ])
            }
          })

          this.closeSidebar()
          showToast('Request canceled', 'is-success', 'is-top')
          this.isRightBarOpen = false
        } catch (e) {
          showToast(e.response.message, 'is-danger', 'is-top')
        }
      }
    },
  },
  async mounted() {
    this.setActivityAttendancePersonal([])
    await this.loadActivityAttendancePersonalList()
    if (this.$route.query.id) {
      await this.openSidebar(null, this.$route.query.id)
    }
  },
}
</script>
